import gql from 'graphql-tag';

const prestacionesTableQuery = gql`
    query prestaciones($whereConditions:PrestacionesWhereWhereConditions,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        prestaciones(where:$whereConditions,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                cliente_id,
                empresa_id,
                nombre,
                general,
                prestacionesInfo{
                    id,
                    antiguedad,
                    dias_vacaciones,
                    prima_vacaciones,
                    dias_aguinaldo
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const queries = { prestacionesTableQuery };

export default queries;
