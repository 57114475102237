import Vue from 'vue';
import Notify from '@/plugins/notify';

export default {
   errorParse(error){
        let ex = "";
        if(typeof error === 'string'){
            Notify.ErrorToast(error);
        }else{
            let obj = Object.values(error);
            let leyenda = ``;

            for (var i = 0; i < obj.length; i++) {
                leyenda+= `* ` + obj[i] + `\n`;
            }

            Notify.ErrorToast(leyenda);
        }
   }
}